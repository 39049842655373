import React, { useEffect, useState } from 'react';
import AuthorPhotos from './authorPhotos';

const SeanBio = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    loadImage();
  }, []);


  const loadImage = () => {
    if (imageLoaded) return;

    const imageUrl = require(`../images/AuthorPics/SeanOilPaint2.jpg`);

    let imgContainer = document.querySelector(`.bio-image-container`);

    if (!imgContainer) return;

    imgContainer.classList.add('loading');
    let preloadedCover = document.createElement('img');
    preloadedCover.src = imageUrl;
    preloadedCover.alt = `Sean Allen`;

    preloadedCover.addEventListener('load', () => {
      imgContainer.classList.remove('loading');
      imgContainer.appendChild(preloadedCover);
    });

    preloadedCover.removeEventListener('load', () => console.log('Aw, Yeah!'));

    setImageLoaded(true);
  }


  return (
    <React.Fragment>
      <section className="SeanBio hero dark">
        <div className="page-header-info">
          <h3 className="page-title">About Sean</h3>
          <hr />
        </div>
        <div className="container blog">
          <div width="350" height="394" className="bio-image-container loading">
            {/* img dynamcially loaded for blur-up effect */}
          </div>

          <p>
            Sean was born in 1978 on a US Air Force base in England.
            Legend has it his mother visited a movie theater in London
            not long before he was due, and <span className="highlight-yellow">
              Sean got to hear <i>Star Wars: Episode IV - A New Hope</i> shortly
              after it arrived in the UK</span>. It’s said that he kicked wildly when he
            heard John Williams’s now-famous score, and it must be true—although the
            kicking stopped (mostly), to this day, <span className="highlight-yellow">Sean
              still gets goosebumps every time he hears that wonderful music</span>.
          </p>


          <p>
            Sean did most of his growing up (or aging, at least) in Colorado.
            During his school years he was a mediocre athlete, a better-than-average student,
            and a terrible dancer. Sean has a degree in Economics, another in
            Engineering and Technology Management, and an eclectic mix of things on his resume.
            <span className="highlight-yellow"> He taught English in Vietnam, was a gigging musician in Minnesota,
              and once co-owned a professional pet care business</span>. Along with <i>Writer</i>,
            Sean's curriculum vitae also includes <i>Software Engineer.</i>
          </p>

          <p>
            Currently, Sean lives in sunny California with his wife,
            a miniature poodle named Col, and a half Chihuahua half Jack Russell
            terrier who answers to the name of Etta (unless she’s distracted by food). Sadly,
            time has done little for Sean's athletic prowess, but his dancing has improved.
            <span className="highlight-yellow"> When he's not writing, you can find him riding his motorcycle, listening to music,
              writing code, or geeking out with a good movie or video game</span>.
          </p>
        </div>
        <hr />
      </section>
      <AuthorPhotos />
    </React.Fragment >
  )
}

export default SeanBio;
