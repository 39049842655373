import React from 'react';
import { Helmet } from 'react-helmet';
import Head from '../components/head';
import Layout from '../components/layout';
import SeanBio from '../components/seanBio';


const AboutSean = () => {
  const PAGE = "About Sean";
  const baseSiteUrl = 'https://authorseanallen.com';

  return (
    <Layout>
      <Head title={PAGE} />
      <Helmet>
        <meta name="description"
          content="Biography for the genre fiction author Sean Allen." />
        <link rel="canonical" href={`${baseSiteUrl}/about-sean`} />
      </Helmet>
      <SeanBio />
    </Layout>
  )
}

export default AboutSean;