import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';

const AuthorPhotos = () => {

  const [isVisible, setVisible] = useState(true);
  const [slide, setSlide] = useState(0);

  const updatePhoto = (slide) => {
    setVisible(!isVisible);
    setSlide(slide);
  }

  // const Sean1 = require(`../images/AuthorPics/Sean_1.webp`);
  // const Sean2 = require(`../images/AuthorPics/Sean_2.webp`);
  // const Sean3 = require(`../images/AuthorPics/Sean_3.webp`);
  // const Sean4 = require(`../images/AuthorPics/Sean_4.webp`);
  // const Sean5 = require(`../images/AuthorPics/Sean_5.webp`);
  // const Sean6 = require(`../images/AuthorPics/Sean_6.webp`);
  // const Sean7 = require(`../images/AuthorPics/Sean_7.webp`);
  // const Sean8 = require(`../images/AuthorPics/Sean_8.webp`);
  // const Sean9 = require(`../images/AuthorPics/Sean_9.webp`);

  const Sean1 = require(`../images/AuthorPics/Sean_1a.jpg`);
  const Sean2 = require(`../images/AuthorPics/Sean_2a.jpg`);
  const Sean3 = require(`../images/AuthorPics/Sean_3a.jpg`);
  const Sean4 = require(`../images/AuthorPics/Sean_4a.jpg`);
  const Sean5 = require(`../images/AuthorPics/Sean_5a.jpg`);
  const Sean6 = require(`../images/AuthorPics/Sean_6a.jpg`);
  const Sean7 = require(`../images/AuthorPics/Sean_7a.jpg`);
  const Sean8 = require(`../images/AuthorPics/Sean_8a.jpg`);
  const Sean9 = require(`../images/AuthorPics/Sean_9a.jpg`);


  return (
    <React.Fragment>
      <section className="hero dark">
        <FsLightbox
          toggler={isVisible}
          slide={slide}
          sources={[
            Sean1,
            Sean2,
            Sean3,
            Sean4,
            Sean5,
            Sean6,
            Sean7,
            Sean8,
            Sean9
          ]}
        />
        <section className="AuthorPhotos">
          <h3 className="page-title">Author Photos</h3>
          <h1 className="photo-instructions highlight-yellow">Photos for use with articles, reviews,
            or as targets for sharp objects . . .
          </h1>
          <p className="photo-credit">Credit Marina Dickey</p>
          <section className="image-grid">
            <img src={Sean1} alt="" onClick={() => updatePhoto(1)} />
            <img src={Sean2} alt="" onClick={() => updatePhoto(2)} />
            <img src={Sean3} alt="" onClick={() => updatePhoto(3)} />
            <img src={Sean4} alt="" onClick={() => updatePhoto(4)} />
            <img src={Sean6} alt="" onClick={() => updatePhoto(6)} />
            <img src={Sean5} alt="" onClick={() => updatePhoto(5)} />
            <img src={Sean9} alt="" onClick={() => updatePhoto(9)} />
            <img src={Sean7} alt="" onClick={() => updatePhoto(7)} />
            <img src={Sean8} alt="" onClick={() => updatePhoto(8)} />
          </section>
        </section>
      </section>
    </React.Fragment>
  )
}

export default AuthorPhotos;